import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { Layout, SEO } from "../../../components/structure";
import {
  Hero,
  Content,
  ExecutiveBio,
  Button,
  CallToAction,
} from "../../../components/blocks";

import styled from "styled-components";
import { mq, brand, font } from "../../../styles";

import english from "../../../../content/translations/en-CA/about.json";
import french from "../../../../content/translations/fr-CA/about.json";

const ExecLeadershipTeam = () => {
  let lang = english;
  const data = useStaticQuery(graphql`
    query {
      heroImg: file(base: { eq: "Plusgrade-Hero-Banner-ELT.jpg" }) {
        ...FullHeroBackground
      }
      rightArrowIconBlue: file(base: { eq: "arrow-right-blue.png" }) {
        ...ButtonIcon
      }
      execKenTile: file(base: { eq: "KenHarris.jpg" }) {
        ...BioTileImage
      }
      execKenPopup: file(base: { eq: "KenHarris.jpg" }) {
        ...BioPopupImage
      }
      execBrettTile: file(base: { eq: "BrettM.jpg" }) {
        ...BioTileImage
      }
      execBrettPopup: file(base: { eq: "BrettM.jpg" }) {
        ...BioPopupImage
      }
      execDanielleTile: file(base: { eq: "DanielleB.jpg" }) {
        ...BioTileImage
      }
      execDaniellePopup: file(base: { eq: "DanielleB.jpg" }) {
        ...BioPopupImage
      }
      execGioTile: file(base: { eq: "GioP.jpg" }) {
        ...BioTileImage
      }
      execGioPopup: file(base: { eq: "GioP.jpg" }) {
        ...BioPopupImage
      }
      execFadyTile: file(base: { eq: "FadyH.jpg" }) {
        ...BioTileImage
      }
      execFadyPopup: file(base: { eq: "FadyH.jpg" }) {
        ...BioPopupImage
      }
      execGeorgeTile: file(base: { eq: "GeorgeY.jpg" }) {
        ...BioTileImage
      }
      execGeorgePopup: file(base: { eq: "GeorgeY.jpg" }) {
        ...BioPopupImage
      }
      execSarahTile: file(base: { eq: "SarahB.jpg" }) {
        ...BioTileImage
      }
      execSarahPopup: file(base: { eq: "SarahB.jpg" }) {
        ...BioPopupImage
      }
      execJayTile: file(base: { eq: "JayM.jpg" }) {
        ...BioTileImage
      }
      execJayPopup: file(base: { eq: "JayM.jpg" }) {
        ...BioPopupImage
      }
    }
  `);

  const ExecutiveContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 35px 70px;
    width: 95%;
    max-width: 1400px;
    margin: auto;
    background: #fff;
    padding: 100px 0;
    @media screen and ${mq.maxMd} {
      display: block;
    }
  `;

  const ExecutiveIntro = styled.div`
    ${font.imports.sherika}

    width: 95%;
    max-width: 1400px;
    margin: 100px auto;
    @media screen and ${mq.maxMd} {
      text-align: center;
    }

    h4 {
      font-family: ${font.family.sherika};
      font-weight: 600;
      font-size: 48px;
      color: ${brand.colors.pg_blue};
    }

    h3 {
      font-family: ${font.family.sherika};
      font-weight: 600;
      font-size: 32px;
      color: #fff;
    }
  `;

  return (
    <Layout>
      <SEO
        title={"Équipe de direction | Plusgrade"}
        description={
          "Rencontrez notre équipe de direction - un groupe diversifié de personnes qui mènent la voie pour faire de Plusgrade le leader mondial des solutions de revenus accessoires."
        }
        keywords={"leadership, ceo, executives"}
      />
      <Hero
        title={"Équipe de\ndirection"}
        subtitle={lang.hero.subtitle}
        image={data["heroImg"]}
        imageStyle={{ objectPosition: "center bottom" }}
        fullBG
      />
      <Content contained>
        <ExecutiveIntro>
          <h4>Rencontrez notre équipe de direction</h4>
          <h3>
            Quand vient le temps de concrétiser notre mission d’être le leader
            mondial des revenus de produits auxiliaires, cette équipe ouvre le
            chemin. Découvrez l’expérience diversifiée qu’ils apportent à
            Plusgrade.
          </h3>
        </ExecutiveIntro>
      </Content>

      <Content whiteBG>
        <ExecutiveContainer>
          <ExecutiveBio
            name={"Ken Harris"}
            title={"Fondateur et PDG"}
            blurb={
              "Ken Harris est le fondateur et le PDG de Plusgrade, l'une des plus grandes entreprises technologiques privées du Canada. Au cours des 15 dernières années, il a conduit l'entreprise à permettre à plus de 200 fournisseurs de voyages dans 60 pays de générer des milliards de nouveaux revenus et d'établir Plusgrade en tant que centrale mondiale de revenus auxiliaires. Ken a obtenu une reconnaissance significative dans toute l'industrie technologique, du prestigieux Deloitte Technology Leadership Award™ à la nomination au Top 40 Under 40™ du Canada. Bien sûr, Ken adore voyager, en particulier partout où il peut s'amuser dans ou sur l'eau."
            }
            profileIcon={data["execKenTile"]}
            profilePopup={data["execKenPopup"]}
            linkedin={"https://www.linkedin.com/in/kenharris/"}
            fr
          />

          <ExecutiveBio
            name={"Jay Malowney"}
            title={"Président, Points"}
            blurb={
              "Jay Malowney est le président de l'unité commerciale Points. Ayant acquis plus de 25 ans d'expérience dans le secteur de la fidélisation, son expertise stimule l'innovation et la croissance dans l'ensemble de l'entreprise.  Avant de rejoindre Plusgrade, Jay a excellé en tant que Directeur Commercial ou Chef de Direction Commercial chez Points, gérant la croissance commerciale, le marketing, la gestion des relations, les équipes de croissance et les partenariats stratégiques. Pendant son temps libre, vous trouverez Jay avec sa femme et ses trois enfants dans un chalet ou en train de faire de la randonnée, de la course à pied et du vélo de montagne sur les nombreux sentiers de l'Ontario."
            }
            profileIcon={data["execJayTile"]}
            profilePopup={data["execJayPopup"]}
            linkedin={"https://www.linkedin.com/in/jay-malowney-3302693/"}
            fr
          />
          <ExecutiveBio
            name={"Brett Mellon"}
            title={"Président, Solutions"}
            blurb={
              "Brett Mellon, en qualité de président de l’unité commerciale Solutions, est un ingénieur accompli qui compte plus de 30 ans d'expérience dans cinq secteurs d'activité différents. Avec un fort accent sur la technologie dans des rôles opérationnels depuis plus d'une décennie, il est reconnu pour ses compétences exceptionnelles en matière de résolution de problèmes et sa compréhension globale des opérations à tous les niveaux de gestion. Lorsqu'il n'est pas au travail, Brett aime tout ce qui a trait à l'été, y compris le golf, les chalets et le temps passé en famille."
            }
            profileIcon={data["execBrettTile"]}
            profilePopup={data["execBrettPopup"]}
            linkedin={"https://www.linkedin.com/in/brett-mellon-99418615/"}
            fr
          />
          <ExecutiveBio
            name={"Sarah Bettencourt"}
            title={"Cheffe des ressources humaines"}
            blurb={
              "Sarah Bettencourt occupe la fonction de cheffe des ressources humaines, où elle dirige tous les aspects du personnel et de la culture, y compris la gestion des performances, la planification des successions et les perspectives stratégiques. Titulaire d'un master en efficacité et conception organisationnelles et forte de plus de 20 ans d'expérience, elle donne offre aux équipes de toute l'entreprise les moyens de s'approprier et de conduire promouvoir une culture de l'engagement et de la haute performance. Pour ses contributions exceptionnelles à la gestion des affaires et des personnes, Sarah a été récompensée par le prix de la femme d'affaires de l'année et le prix de la femme dirigeante. Personnellement, Sarah aime tout ce qui touche à la famille, voyager à travers le monde et faire du vélo de route."
            }
            profileIcon={data["execSarahTile"]}
            profilePopup={data["execSarahPopup"]}
            linkedin={
              "https://www.linkedin.com/in/sarah-bettencourt-mhrm-2bb5754/"
            }
            fr
          />
          <ExecutiveBio
            name={"Danielle Brown"}
            title={"Cheffe du marketing"}
            blurb={
              "Danielle Brown est cheffe du marketing et supervise la science des données et l'analyse, la marque et le contenu, ainsi que le marketing des partenaires, des produits et de la performance. Elle crée et dirige des équipes heureuses, performantes et axées sur le profit qui stimulent la croissance. L'expertise de Danielle en matière de gestion du changement, de négociation et d'innovation s'étend à divers secteurs, notamment la fidélisation, le commerce électronique, le divertissement et la technologie. Conférencière internationalement recherchée, elle aborde des sujets tels que le leadership et la croissance dans les entreprises B2B et B2C. Pendant son temps libre, Danielle est une fervente adepte du curling et a une certaine addiction aux tatouages."
            }
            profileIcon={data["execDanielleTile"]}
            profilePopup={data["execDaniellePopup"]}
            linkedin={"https://www.linkedin.com/in/danielle-brown-7aaa3427/"}
            fr
          />
          <ExecutiveBio
            name={"Giovanni Pizzoferrato"}
            title={"Chef de la technologie"}
            blurb={
              "Giovanni (Gio) Pizzoferrato est chef de la technologie chez Plusgrade, où il dirige le développement de des services avancés en nuage, de données et de logiciels. Avec plus de 20 ans d'expérience en leadership technologique dans les secteurs des télécommunications, de la vente au détail, de la chaîne d'approvisionnement et de la finance, Gio est connu pour sa vision stratégique et son sens de l'innovation. Il se concentre sur l'amélioration de l'expérience des utilisateurs et de l'efficacité opérationnelle tout en mettant l'accent sur le mentorat pour favoriser la croissance de l'équipe. Reconnu comme un leader avant-gardiste dans le domaine de la technologie cloud, Gio repousse constamment les limites. Au cours de sa carrière, il a occupé des postes de direction chez TELUS, Canadian Tire, Kinaxis, Manulife et la Banque nationale du Canada. En dehors du travail, Gio aime voyager et cuisiner des pizzas."
            }
            profileIcon={data["execGioTile"]}
            profilePopup={data["execGioPopup"]}
            linkedin={"https://www.linkedin.com/in/giopizzo/"}
            fr
          />
          <ExecutiveBio
            name={"Fady Hammal"}
            title={"Vice-président principal et chef de conseil"}
            blurb={
              "Fady Hammal dirige les fonctions juridiques, de gouvernance d'entreprise et de conformité de Plusgrade en tant que premier vice-président, avocat général et secrétaire général. Il supervise également l'évolution du cadre de gestion des risques de l'entreprise. Ayant acquis plus de 15 ans d'expérience, Fady a perfectionné son expertise chez Osler, Hoskin & Harcourt LLP et à la Banque nationale du Canada, en se concentrant sur les fusions et acquisitions, la gouvernance d'entreprise, les technologies de l'information, les partenariats, le capital-risque, les marchés de capitaux et les services financiers. Il est titulaire d'une licence en droit (LLB) et d'un doctorat en droit (JD) de l'Université de Montréal et est admis au Barreau du Québec. Lorsqu'il ne façonne pas le paysage juridique de Plusgrade, il aime rester actif, courir, s'entraîner et voyager avec sa famille."
            }
            profileIcon={data["execFadyTile"]}
            profilePopup={data["execFadyPopup"]}
            linkedin={"https://www.linkedin.com/in/fady-hammal-93a815b0/"}
            fr
          />
          <ExecutiveBio
            name={"George Yao"}
            title={"Chef des finances"}
            blurb={
              "George Yao est le chef des finances et supervise les activités mondiales de FP&A, de comptabilité, de fiscalité, de trésorerie, de paiements, de stratégie, de développement de l'entreprise, d'opérations commerciales, d'affaires juridiques et de conformité. Fort de plus de 20 ans d'expérience, George était auparavant directeur général chez Morgan Stanley et TD Securities. George a su démontrer son expertise dans l'exécution d'opérations de financement et de fusions-acquisitions de premier plan. Il est titulaire d'un MBA de Rotman, d'un MSc en informatique et d'un HBSc en biosciences de l'Université Western. En tant que penseur stratégique, il se concentre en permanence sur la croissance et l'efficacité. Lorsque cela est possible, George aime faire quelques tours de piste."
            }
            profileIcon={data["execGeorgeTile"]}
            profilePopup={data["execGeorgePopup"]}
            linkedin={"https://www.linkedin.com/in/georgeyao/"}
            fr
          />

          <div style={{ visibility: "hiddn", width: "250px" }}></div>
        </ExecutiveContainer>

        <CallToAction
          responsiveButton
          title={"Joignez-vous à l’équipe"}
          subtitle={
            "Nous sommes toujours à la recherche de personnes motivées et créatives pour rejoindre notre équipe."
          }
        >
          <Button
            destination={"/fr/carrieres/"}
            label={"Voir les postes vacants"}
            icon={data["rightArrowIconBlue"]}
            white
            blueText
            pill
          />
        </CallToAction>
      </Content>
    </Layout>
  );
};

export default ExecLeadershipTeam;
